import produce from 'immer';
import { Reducer, handleActions, Action } from 'redux-actions';
import { AnyAction } from 'redux';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import axios, { AxiosResponse, CancelToken } from 'axios';
import { getAuthorizationHeader } from '../cores/getAuthorizationHeader';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { action, PayloadAction, PayloadMetaAction } from 'typesafe-actions';
import { reverse, sortBy } from 'lodash';
import qs from 'qs';

export enum AnalyticsTypes {
  getAnalytics = '@analytics/getAnalytics',
  truncateAnalytics = '@analytics/truncateAnalytics'
}

export interface AnalyticsState {
  data: any;
}

export const AnalyticsActions = {
  truncateAnalytics: () => action(AnalyticsTypes.truncateAnalytics),
  getAnalytics: createAsyncSagaAction(
    AnalyticsTypes.getAnalytics,
    (endPoint: string, params: Object, cancelToken?: CancelToken) =>
      axios.get(`${endPoint}/`, {
        headers: getAuthorizationHeader(),
        params,
        cancelToken,
        paramsSerializer: params => qs.stringify(params, { indices: false })
      })
  )
};

const initialState: AnalyticsState = {
  data: null
};

export default handleActions<AnalyticsState, any>(
  {
    [AnalyticsTypes.truncateAnalytics]: state => {
      return produce(state, draft => {
        draft.data = null;
      });
    },
    ...createAsyncSagaReducerMap(AnalyticsTypes.getAnalytics, {
      onSuccess: (state, action: PayloadMetaAction<string, AxiosResponse<any>, [string]>) => {
        return produce(state, draft => {
          if (action.meta[0] === 'analytics/user_status') {
            draft.data = action.payload.data;
          } else if (Array.isArray(action.payload.data)) {
            draft.data = reverse(sortBy(action.payload.data, ({ date }) => new Date(date).getTime()));
          } else {
            draft.data = action.payload.data;
          }
        });
      }
    })
  },
  initialState
);
