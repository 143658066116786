import React, { FC, memo } from 'react';
import styles from './Table.module.scss';
import { TableDataOptions } from '../../cores/toTableData';
import { map } from 'lodash';
// @ts-ignore
import hex2rgba from 'hex2rgba';
import classNames from 'classnames';

interface Props {
  tableData: any;
  defaultOptions: TableDataOptions;
}

const Table: FC<Props> = memo(({ tableData, defaultOptions }) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {map(defaultOptions.fields, ({ name }, key) => (
            <th key={key}>{name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {map(tableData, (values, key) => {
          return (
            <tr key={key}>
              {map(values, ({ name, value, color, numeric, tenDaysAgoDivisionLine }, key2) => {
                return (
                  <td
                    key={key2}
                    className={classNames(
                      name && styles[name],
                      !numeric && styles.isNotNumeric,
                      tenDaysAgoDivisionLine && styles.tenDaysAgoDivisionLine
                    )}
                    style={{ backgroundColor: color ? hex2rgba(color, 0.3) : '#fff' }}
                  >
                    {value}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});

export default Table;
