function getBoundingRect(element: HTMLElement) {
  const r = { x: element.offsetLeft, y: element.offsetTop };

  if (element.offsetParent instanceof HTMLElement) {
    const tmp = getBoundingRect(element.offsetParent);
    r.x += tmp.x;
    r.y += tmp.y;
  }

  return r;
}

export function getPosition(e: MouseEvent): [number, number] {
  const { target, clientX, clientY } = e;
  if (target === null || !(target instanceof HTMLElement)) {
    return [-1, -1];
  }

  const boundingRect = getBoundingRect(target);
  return [clientX - boundingRect.x, clientY - boundingRect.y];
}
