import React, { useEffect, useState } from 'react';
import styles from './DealerAnalyticsTable.module.scss';
import { useTypedSelector } from '../../cores/useTypedSelector';
import { orderBy } from 'lodash';
import { DealerTypes } from '../../store/dealer';
import DealerAnalyticsTableRow from '../DealerAnalyticsTableRow/DealerAnalyticsTableRow';
import { DealerActivity } from '../../store/dealer/interface';
import { MdArrowDropUp } from 'react-icons/md';
import classNames from 'classnames';
import Ink from 'react-ink';
import Loading from '../Loading/Loading';
import XLSX from 'xlsx';
import { DateTime } from 'luxon';
import XlsxDownLoadButton from '../XlsxDownLoadButton/XlsxDownLoadButton';

import { ReactComponent as ZeroMan } from '../../images/zero-man.svg';

type SortKey = keyof DealerActivity;
type SortValue = 'asc' | 'desc';

const DealerAnalyticsTable: React.FC<{}> = () => {
  const { isLoading, dealerActivity } = useTypedSelector(({ dealer: { dealerActivity }, loading: { asyncMap } }) => ({
    isLoading: asyncMap[DealerTypes.getDealerActivity] > 0,
    dealerActivity
  }));
  const [sortedDealerActivity, setSortedDealerActivity] = useState<DealerActivity[] | null>(null);
  const [sortKey, setSortKey] = useState<SortKey>('office');
  const [sortValue, setSortValue] = useState<SortValue>('asc');
  const isActive = (key: SortKey) => key === sortKey;

  useEffect(() => {
    setSortedDealerActivity(
      orderBy(dealerActivity, dealer => (sortKey === 'office' ? dealer.office.toLowerCase() : dealer[sortKey]), [sortValue])
    );
  }, [dealerActivity, sortKey, sortValue]);

  const onClickSortableHeader = (key: SortKey) => {
    if (key === sortKey) {
      setSortValue(sortValue === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortValue('desc');
    }
  };

  const renderArrow = (key: SortKey) => {
    return key === sortKey ? (
      <div className={classNames(styles.arrowWrapper, { [styles.reverse]: sortValue === 'desc' })}>
        <MdArrowDropUp size={24} />
      </div>
    ) : null;
  };

  const onClickDownLoadXlsx = () => {
    if (!sortedDealerActivity) return;

    const headerKeys: [string, keyof DealerActivity][] = [
      ['성함 및 직함', 'full_name'],
      ['소속 지점', 'office'],
      ['zero-견적', 'zero_bids_count'],
      ['zero-선택', 'zero_selected_cars_count'],
      ['zero-거래', 'zero_traded_cars_count'],
      ['일반-견적', 'basic_bids_count'],
      ['일반-선택', 'basic_selected_cars_count'],
      ['일반-거래', 'basic_traded_cars_count'],
      ['합계-견적', 'sum_bids_count'],
      ['합계-선택', 'sum_selected_cars_count'],
      ['합계-거래', 'sum_traded_cars_count'],
      ['입찰정지 횟수', 'mistakes_count'],
      ['경고 횟수', 'warnings_count']
    ];
    const xlsxList = sortedDealerActivity.map(data =>
      headerKeys.reduce((acc, [header, key]) => ({ ...acc, [header]: data[key] }), {})
    );
    const workSheet = XLSX.utils.json_to_sheet(xlsxList, { header: headerKeys.map(([header]) => header) });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet);
    XLSX.writeFile(workBook, `담당자별 데이터-${DateTime.local().toFormat('yyyyMMddHHmmss')}.xlsx`);
  };

  if (isLoading) return <Loading />;

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.xlsxDownLoadButtonWrapper}>
        <XlsxDownLoadButton onClick={onClickDownLoadXlsx} />
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th rowSpan={2}>성함 및 직함</th>
            <th
              rowSpan={2}
              onClick={() => onClickSortableHeader('office')}
              className={classNames({ [styles.isActive]: isActive('office') }, styles.sortAbleHeader)}
            >
              <div className={styles.buttonWrapper}>
                <div className={styles.headerContent}>소속 지점{renderArrow('office')}</div>
                <Ink />
              </div>
            </th>
            <th colSpan={3}>
              zero 경매 <ZeroMan height={20} style={{ display: 'inline-block', verticalAlign: 'middle' }} />
            </th>
            <th colSpan={3}>일반 경매</th>
            <th colSpan={3}>합계</th>
            <th
              rowSpan={2}
              onClick={() => onClickSortableHeader('mistakes_count')}
              className={classNames({ [styles.isActive]: isActive('mistakes_count') }, styles.sortAbleHeader)}
            >
              <div className={styles.buttonWrapper}>
                <div className={styles.headerContent}>입찰정지 횟수 {renderArrow('mistakes_count')}</div>
                <Ink />
              </div>
            </th>
            <th
              rowSpan={2}
              onClick={() => onClickSortableHeader('warnings_count')}
              className={classNames({ [styles.isActive]: isActive('warnings_count') }, styles.sortAbleHeader)}
            >
              <div className={styles.buttonWrapper}>
                <div className={styles.headerContent}>경고 횟수 {renderArrow('warnings_count')}</div>
                <Ink />
              </div>
            </th>
          </tr>
          <tr>
            <th
              onClick={() => onClickSortableHeader('basic_bids_count')}
              className={classNames({ [styles.isActive]: isActive('basic_bids_count') }, styles.sortAbleHeader)}
            >
              <div className={styles.buttonWrapper}>
                <div className={styles.headerContent}>견적 {renderArrow('basic_bids_count')}</div>
                <Ink />
              </div>
            </th>
            <th
              onClick={() => onClickSortableHeader('basic_selected_cars_count')}
              className={classNames({ [styles.isActive]: isActive('basic_selected_cars_count') }, styles.sortAbleHeader)}
            >
              <div className={styles.buttonWrapper}>
                <div className={styles.headerContent}>선택 {renderArrow('basic_selected_cars_count')}</div>
                <Ink />
              </div>
            </th>
            <th
              onClick={() => onClickSortableHeader('basic_traded_cars_count')}
              className={classNames({ [styles.isActive]: isActive('basic_traded_cars_count') }, styles.sortAbleHeader)}
            >
              <div className={styles.buttonWrapper}>
                <div className={styles.headerContent}>거래 {renderArrow('basic_traded_cars_count')}</div>
                <Ink />
              </div>
            </th>
            <th
              onClick={() => onClickSortableHeader('zero_bids_count')}
              className={classNames({ [styles.isActive]: isActive('zero_bids_count') }, styles.sortAbleHeader)}
            >
              <div className={styles.buttonWrapper}>
                <div className={styles.headerContent}>견적 {renderArrow('zero_bids_count')}</div>
                <Ink />
              </div>
            </th>
            <th
              onClick={() => onClickSortableHeader('zero_selected_cars_count')}
              className={classNames({ [styles.isActive]: isActive('zero_selected_cars_count') }, styles.sortAbleHeader)}
            >
              <div className={styles.buttonWrapper}>
                <div className={styles.headerContent}>선택 {renderArrow('zero_selected_cars_count')}</div>
                <Ink />
              </div>
            </th>
            <th
              onClick={() => onClickSortableHeader('zero_traded_cars_count')}
              className={classNames({ [styles.isActive]: isActive('zero_traded_cars_count') }, styles.sortAbleHeader)}
            >
              <div className={styles.buttonWrapper}>
                <div className={styles.headerContent}>거래 {renderArrow('zero_traded_cars_count')}</div>
                <Ink />
              </div>
            </th>
            <th
              onClick={() => onClickSortableHeader('sum_bids_count')}
              className={classNames({ [styles.isActive]: isActive('sum_bids_count') }, styles.sortAbleHeader)}
            >
              <div className={styles.buttonWrapper}>
                <div className={styles.headerContent}>견적 {renderArrow('sum_bids_count')}</div>
                <Ink />
              </div>
            </th>
            <th
              onClick={() => onClickSortableHeader('sum_selected_cars_count')}
              className={classNames({ [styles.isActive]: isActive('sum_selected_cars_count') }, styles.sortAbleHeader)}
            >
              <div className={styles.buttonWrapper}>
                <div className={styles.headerContent}>선택 {renderArrow('sum_selected_cars_count')}</div>
                <Ink />
              </div>
            </th>
            <th
              onClick={() => onClickSortableHeader('sum_traded_cars_count')}
              className={classNames({ [styles.isActive]: isActive('sum_traded_cars_count') }, styles.sortAbleHeader)}
            >
              <div className={styles.buttonWrapper}>
                <div className={styles.headerContent}>거래 {renderArrow('sum_traded_cars_count')}</div>
                <Ink />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedDealerActivity?.map((dealer, index) => (
            <DealerAnalyticsTableRow dealer={dealer} key={`${dealer.full_name}-${index}`} index={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DealerAnalyticsTable;
