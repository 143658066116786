import React, { useEffect } from 'react';
import styles from './DealersPage.module.scss';
import Layout from '../../components/Layout';
import DealersHeader from '../../components/Dealers/DealersHeader/DealersHeader';
import DealersList from '../../components/Dealers/DealersList/DealersList';
import { useRouter } from '../../cores/useRouter';
import base64url from 'base64-url';
import Content from '../../components/Content/Content';

const DealersPage: React.FC<{}> = () => {
  const { query, history } = useRouter();
  useEffect(() => {
    if (!query.get('key') || !query.get('order')) {
      if (!query.get('key')) {
        query.set('key', base64url.encode('담당자 리스트'));
      }
      if (!query.get('order')) {
        query.set('order', 'recent');
      }
      history.push(`?${query.toString()}`);
    }
  }, []);
  return (
    <Layout>
      <Content>
        <div className={styles.dealers}>
          <DealersHeader />
          <DealersList />
        </div>
      </Content>
    </Layout>
  );
};

export default DealersPage;
