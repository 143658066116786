import React, { ReactNode, forwardRef, HTMLAttributes, CSSProperties } from 'react';
import styles from './ToolTip.module.scss';
import { useTransition, animated } from 'react-spring';
import classNames from 'classnames';

type Direction = 'left' | 'right';
interface Props {
  isVisible?: boolean;
  direction: Direction;
  children: ReactNode;
  className?: string;
  isDisableAnimate?: boolean;
  style?: CSSProperties;
}

const ToolTip: React.ForwardRefRenderFunction<HTMLDivElement, Props & HTMLAttributes<HTMLDivElement>> = (
  { isVisible = true, children, className, direction, isDisableAnimate = false, style },
  ref
) => {
  const isLeft = direction === 'left';
  const isRight = direction === 'right';
  const transitions = useTransition(isVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    immediate: isDisableAnimate ? true : false,
  });
  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={{ ...props, ...style }}
              className={classNames(styles.tooltip, { [styles.left]: isLeft, [styles.right]: isRight }, className)}
              ref={ref}
            >
              {children}
            </animated.div>
          )
      )}
    </>
  );
};

export default forwardRef(ToolTip);
