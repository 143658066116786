import React, { CSSProperties, FC, RefObject, useCallback, useEffect, useRef } from 'react';

interface Props {
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  onClickOut: () => void;
}

const ClickOut: FC<Props> = ({ className, style, children, onClickOut }) => {
  const wrapRef = useRef<HTMLDivElement>(null);
  const handleClickOut = useCallback((e: MouseEvent) => {
    if (wrapRef.current && !wrapRef.current.contains(e.target as Node)) {
      onClickOut();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOut);
    return () => {
      document.removeEventListener('mousedown', handleClickOut);
    };
  }, []);

  return (
    <div ref={wrapRef} style={style} className={className}>
      {children}
    </div>
  );
};

export default ClickOut;
