import { all } from 'redux-saga/effects';
import dealer from './dealer';
import loading from './loading';
import sequence from './sequence';
import apiErrorHandling from './apiErrorHandling';
import toast from './toast';
import authentication from './authentication';
import analytics from './analytics';

export default function* rootSaga() {
  yield all([dealer(), loading(), sequence(), apiErrorHandling(), toast(), authentication(), analytics()]);
}
