import { all, fork, put, take } from 'redux-saga/effects';
import { AuthenticationActions, AuthenticationTypes } from '../store/authentication';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { PayloadAction } from 'typesafe-actions';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { ToastActions, ToastMessageTypes } from '../store/toast';
import { getLocalStorageToken } from '../cores/getLocalStorageToken';

export default function*() {
  yield all([...createAsyncSaga(AuthenticationActions), getToken(), watchSetLogin(), watchSetLogout()]);
}

export const tokenName = `token`;

function* getToken() {
  const token = getLocalStorageToken();
}

function* watchSetLogin() {
  while (true) {
    const {
      payload: { data }
    }: PayloadAction<
      typeof AuthenticationTypes.setLogin,
      AxiosResponse<{ token: string; user: { is_staff: boolean } }>
    > = yield take(AuthenticationActions.setLogin.success);

    const token = get(data, 'token');
    if (token) {
      localStorage.setItem(tokenName, token);
      yield put(AuthenticationActions.setToken(token));
      yield put(
        ToastActions.createToast({
          type: ToastMessageTypes.Success,
          message: '로그인 되었습니다.'
        })
      );
    }
  }
}

function* watchSetLogout() {
  while (true) {
    yield take(AuthenticationTypes.setLogout);
    localStorage.removeItem(tokenName);
    yield fork(getToken);
  }
}
