import { all, fork, put, take } from 'redux-saga/effects';
import { AuthenticationActions, AuthenticationTypes } from '../store/authentication';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { Action, PayloadAction } from 'typesafe-actions';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { ToastActions, ToastMessageTypes } from '../store/toast';
import { AnalyticsActions } from '../store/analytics';

export default function*() {
  yield all([...createAsyncSaga(AnalyticsActions)]);
}
