import React, { ReactNode } from 'react';
import styles from './Content.module.scss';

interface Props {
  children: ReactNode;
  isLogin?: boolean;
}

const Content: React.FC<Props> = ({ children, isLogin }) => {
  return <div className={isLogin ? styles.loginContent : styles.content}>{children}</div>;
};

export default Content;
