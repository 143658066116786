import React, { FC, memo, ReactNode } from 'react';
import styles from './Typography.module.scss';
import classNames from 'classnames';
import { get } from 'lodash';

interface Props {
  variant: 'label' | 'text';
  children?: ReactNode;
  className?: string;
}

const Typography: FC<Props> = memo(({ className, variant, children }) => {
  return (
    <div data-testid="typography" className={classNames(styles.typography, get(styles, variant), className)}>
      {children}
    </div>
  );
});

export default Typography;
