import { chain, get, groupBy } from 'lodash';

export interface ChartDataOptions {
  fields: Array<ChartDataField>;
  isRatio?: boolean;
}

export enum ChartType {
  Line,
  Bar
}

interface ChartDataField {
  name: string;
  suffix?: string;
  description?: string;
  value?: string;
  color: Color;
  type?: ChartType;
  disabled?: boolean;
  getValue?: (fields: any) => number | string;
  withCalculation?: boolean;
  standardValue?: number;
  calculationGroup?: string;
}

interface ChartDataSerialize {
  name: string;
  suffix?: string;
  description?: string;
  type?: ChartType;
  color: Color;
  values: Array<number>;
  disabled: boolean;
  withCalculation: boolean;
  standardValue?: number;
  calculationGroup?: string;
}

export interface ChartData {
  max: number;
  min: number;
  data: Array<ChartDataSerialize>;
  dates: Array<string>;
  isRatio?: boolean;
  disabled?: boolean;
}

export enum Color {
  gray = '#dadfe1',
  blue = '#00b0ff',
  red = '#ec6878',
  green = '#39c889',
  yellow = '#f1c40f',
  dark = '#34495e',
  hidden = '#869ab7'
}

export function toChartData(data: any, { isRatio, fields }: ChartDataOptions): ChartData {
  const serializeData: Array<ChartDataSerialize> = chain(fields)
    .map(
      ({
        disabled = false,
        suffix,
        description,
        withCalculation = true,
        type = ChartType.Line,
        name,
        color,
        value,
        getValue,
        standardValue,
        calculationGroup
      }) => {
        return {
          name,
          color,
          type,
          suffix,
          disabled,
          description,
          standardValue,
          withCalculation,
          calculationGroup,
          values: chain(data)
            .map(dataFields => {
              const result = value ? get(dataFields, value) : getValue ? getValue(dataFields) : null;

              if (isNaN(result)) {
                return 0;
              }

              return result;
            })
            .value()
        };
      }
    )
    .value();

  const flatMapped = chain(serializeData)
    .filter(data => {
      const { disabled, withCalculation } = data;

      return !disabled || !withCalculation;
    })
    .flatMap(({ values }) => {
      return values;
    });

  const maxRatio = Math.ceil((flatMapped.max().value() * 1.2) / 10) * 10;

  return {
    isRatio,
    max: isRatio ? (maxRatio > 100 ? 100 : maxRatio) : round(flatMapped.max().value()),
    min: 0,
    data: serializeData,
    dates: chain(data)
      .map('date')
      .value()
  };
}

const round = (x: number) => {
  let result = null;
  const value = Math.abs(x);
  const isMinus = x < 0;

  if (value >= 100000) result = Math.ceil(value * 0.0001) * 10000;
  else if (value >= 10000) result = Math.ceil(value * 0.001) * 1000;
  else if (value > 1000) result = Math.ceil(value * 0.01) * 100;
  else result = value;

  if (isMinus) result *= -1;

  return result;
};
