import { tokenName } from '../sagas/authentication';

export function getAuthorizationHeader() {
  const token = localStorage.getItem(tokenName);

  if (typeof token === 'string' && token.length > 0) {
    return {
      Authorization: `JWT ${token}`
    };
  }

  return {};
}
