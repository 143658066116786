import { combineReducers } from 'redux';
import dealer from './dealer';
import loading from './loading';
import toast from './toast';
import sequence from './sequence/sequence';
import authentication from './authentication';
import analytics, { AnalyticsState } from './analytics';
import responsive, { ResponsiveState } from './responsive';

const rootReducer = combineReducers({
  dealer,
  loading,
  toast,
  sequence,
  authentication,
  analytics,
  responsive
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
