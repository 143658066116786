import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from './Chart.module.scss';
import produce from 'immer';
import { ChartData, ChartDataOptions, ChartType, Color, toChartData } from '../../cores/toChartData';
import {
  Circle,
  DropShadow,
  Graphics,
  Lines,
  LineVertices,
  PI,
  Rectangle,
  RoundedRectangle,
  Text,
  Vertices
} from '../../cores/Graphics';
import classNames from 'classnames';
import { vec2 } from 'gl-matrix';
import { getPosition } from '../../cores/getPosition';
import { map, forEach, chain, maxBy, groupBy, filter, flatMap, get, sumBy } from 'lodash';

interface Props {
  data: any;
  defaultOptions: ChartDataOptions;
}

const marginLeft = 100;
const marginRight = 50;
const marginTop = 30;
const marginBottom = 150;

const textRotation = -PI * 0.25;
const horizontalLines = 11;

let graphics: Graphics | null = null;
let graphics2: Graphics | null = null;

function isCrash(position: [number, number], rectangle: Rectangle) {
  return (
    position[0] > rectangle.position[0] &&
    position[1] > rectangle.position[1] &&
    position[0] <= rectangle.position[0] + rectangle.width &&
    position[1] <= rectangle.position[1] + rectangle.height
  );
}

const Chart: FC<Props> = memo(({ data, defaultOptions }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvas2Ref = useRef<HTMLCanvasElement>(null);

  const [isCursorPointer, setCursorPointer] = useState(false);
  const [mousePosition, setMousePosition] = useState<[number, number]>([0, 0]);
  const [selectedChartField, setSelectedChartField] = useState<number>(-1);
  const [options, setOptions] = useState<ChartDataOptions>(defaultOptions);
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const [width, setWidth] = useState(window.innerWidth);

  const onResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    setChartData(toChartData(data, options));
  }, [data, options]);

  const onMouseMove = useCallback(e => {
    const mousePosition = getPosition(e);
    const contentElement = document.getElementById('content');
    if (contentElement) {
      mousePosition[1] += contentElement.scrollTop;
    }
    setMousePosition(mousePosition);
  }, []);

  const onMouseDown = useCallback(
    e => {
      if (graphics2 !== null) {
        const mousePosition = getPosition(e);
        const contentElement = document.getElementById('content');

        if (contentElement) {
          mousePosition[1] += contentElement.scrollTop;
        }

        const hitArea2 = graphics2.findAllObjectTag<Rectangle>('hitArea2');
        for (let i = 0, length = hitArea2.length; i < length; i++) {
          if (isCrash(mousePosition, hitArea2[i])) {
            setOptions(
              produce(options, draft => {
                draft.fields[i].disabled = !options.fields[i].disabled;
              })
            );
            break;
          }
        }
      }
    },
    [mousePosition, options]
  );

  useEffect(() => {
    if (graphics2 === null) {
      return;
    }

    const background = graphics2.findObjectTag<Rectangle>('popupBackground')!;
    const dateText = graphics2.findObjectTag<Text>('popupDateText')!;
    const fieldCircles = graphics2.findAllObjectTag<Circle>('popupFieldCircle')!;
    const fieldTexts = graphics2.findAllObjectTag<Text>('popupFieldText')!;

    if (selectedChartField !== -1 && chartData !== null) {
      const { data, dates } = chartData;

      const backgroundPosition = vec2.fromValues(mousePosition[0], mousePosition[1]);

      background.position = backgroundPosition;
      background.visible = true;

      dateText.text = dates[selectedChartField];
      dateText.visible = true;

      const widths = [dateText.getWidth()];

      for (let i = 0, length = data.length; i < length; i++) {
        const { values, name, disabled, suffix } = data[i];
        const value = values[selectedChartField];
        const opacity = disabled ? 0.5 : 1;

        fieldTexts[i].text = `${name}: ${typeof value === 'number' ? value.toLocaleString() : '-'}`;
        if (suffix) {
          fieldTexts[i].text += suffix;
        }
        fieldTexts[i].visible = true;
        fieldCircles[i].visible = true;
        fieldTexts[i].opacity = opacity;
        fieldCircles[i].opacity = opacity;
        widths.push(fieldTexts[i].getWidth());
      }

      const maxWidth = maxBy(widths);
      background.width = (maxWidth || 100) + 35;
      background.height = fieldTexts.length * 20 + 35;

      const backgroundHalfWidth = background.width * 0.5;

      const minX = marginLeft + backgroundHalfWidth;
      const maxX = canvas2Ref.current!.width - marginRight - backgroundHalfWidth;

      const minY = marginTop + background.height;
      const maxY = canvas2Ref.current!.height - marginBottom;

      if (background.position[0] < minX) {
        background.position[0] = minX;
      }

      if (background.position[0] > maxX) {
        background.position[0] = maxX;
      }

      if (background.position[1] < minY) {
        background.position[1] = minY;
      }

      if (background.position[1] > maxY) {
        background.position[1] = maxY;
      }

      const dateTextPosition = vec2.create();

      vec2.add(background.position, background.position, vec2.fromValues(-background.width * 0.5, -background.height - 10));
      vec2.add(dateTextPosition, backgroundPosition, vec2.fromValues(10, 10));

      dateText.position = dateTextPosition;

      for (let i = 0, length = data.length; i < length; i++) {
        const textY = 20 * i + 21;

        const fieldTextPosition = vec2.create();
        const fieldCirclePosition = vec2.create();
        vec2.add(fieldTextPosition, dateTextPosition, vec2.fromValues(15, textY + 1));
        vec2.add(fieldCirclePosition, dateTextPosition, vec2.fromValues(5, textY + 7));
        fieldTexts[i].position = fieldTextPosition;
        fieldCircles[i].position = fieldCirclePosition;
      }
    } else {
      background.visible = false;
      dateText.visible = false;

      for (let i = 0, length = fieldCircles.length; i < length; i++) {
        fieldCircles[i].visible = false;
      }

      for (let i = 0, length = fieldTexts.length; i < length; i++) {
        fieldTexts[i].visible = false;
      }
    }
  }, [selectedChartField, chartData, mousePosition]);

  useEffect(() => {
    if (graphics2 !== null) {
      const hitArea = graphics2.findAllObjectTag<Rectangle>('hitArea');

      let selectedChartField = -1;
      for (let i = 0, length = hitArea.length; i < length; i++) {
        if (isCrash(mousePosition, hitArea[i])) {
          hitArea[i].visible = true;
          selectedChartField = i;
        } else {
          hitArea[i].visible = false;
        }
      }

      const hitArea2 = graphics2.findAllObjectTag<Rectangle>('hitArea2');

      let isCrashedHitArea2 = false;
      for (let i = 0, length = hitArea2.length; i < length; i++) {
        if (isCrash(mousePosition, hitArea2[i])) {
          isCrashedHitArea2 = true;
          break;
        }
      }

      setCursorPointer(isCrashedHitArea2);
      setSelectedChartField(selectedChartField);

      graphics2.render();
    }
  }, [mousePosition]);

  useEffect(() => {
    if (canvasRef.current && canvas2Ref.current && chartData) {
      if (graphics !== null) {
        graphics.dispose();
      }

      if (graphics2 !== null) {
        graphics2.dispose();
      }

      const canvas = canvasRef.current;
      const canvas2 = canvas2Ref.current;

      if (!canvas.parentNode || !(canvas.parentNode instanceof HTMLElement)) {
        return;
      }

      canvas2.addEventListener('mousemove', onMouseMove);
      canvas2.addEventListener('mousedown', onMouseDown);

      const width = canvas.parentNode.offsetWidth;
      const height = 700;

      graphics = new Graphics(canvas, {
        width,
        height
      });

      graphics2 = new Graphics(canvas2, {
        width,
        height
      });

      const { max, min, data: fields, dates, isRatio } = chartData;
      const average = max - min;

      const chartWidth = width - marginLeft - marginRight;
      const chartHeight = height - marginBottom - marginTop;
      const datesLength = dates.length;
      const horizontalLineSize = horizontalLines - 1;
      const oneWidth = chartWidth / datesLength;
      const oneHalfWidth = oneWidth * 0.5;
      const oneHeight = chartHeight / horizontalLineSize;
      const oneHeightValue = (max - min) / horizontalLineSize;

      const getX = (value: number) => {
        return value * oneWidth + oneHalfWidth + marginLeft;
      };

      const getY = (value: number) => {
        return (1 - (value - min) / average) * chartHeight + marginTop;
      };

      const getBarY = (value: number) => {
        return ((value - min) / average) * chartHeight + marginTop;
      };

      const vertices: LineVertices = [];
      const chartEndX = chartWidth + marginLeft;

      for (let i = 0; i < horizontalLines; i++) {
        const lineY = i * oneHeight + marginTop;
        const text = i * oneHeightValue;

        if (!isNaN(text)) {
          let parsedText = Math.round(text).toLocaleString();

          if (isRatio) {
            parsedText += '%';
          }

          graphics.add(
            new Text(vec2.fromValues(marginLeft - 5, height - lineY - marginBottom + marginTop + 0.5), parsedText, {
              color: Color.dark,
              fontSize: 12,
              textAlign: 'right'
            })
          );
        }

        vertices.push([vec2.fromValues(marginLeft, lineY), vec2.fromValues(chartEndX, lineY)]);
      }

      graphics.add(Lines.fromVertices(vertices, { color: Color.gray }));

      let prevTextX = marginLeft;
      let barCount = 0;
      const totalBarCount = chain(fields)
        .filter(({ disabled, type }) => type === ChartType.Bar && !disabled)
        .size()
        .value();
      const textY = chartHeight + marginTop + 50 + 15;
      const textCircleRadius = 5;
      const barOneWidth = (oneWidth / totalBarCount) * 0.5;

      const groups = groupBy(fields, 'calculationGroup');
      const groupMaxMap: { [key: string]: number } = {};

      forEach(groups, (group, key) => {
        if (key !== 'undefined') {
          const value = maxBy(flatMap(filter(group, ({ disabled }) => !disabled), 'values'));
          groupMaxMap[key] = value * 1.25;
        }
      });

      for (let i = 0, length = fields.length; i < length; i++) {
        const { name, color, description, values, type, disabled, withCalculation, standardValue, calculationGroup } = fields[i];
        const opacity = disabled ? 0.5 : 1;

        const text = new Text(vec2.fromValues(prevTextX + textCircleRadius * 2 + 5, textY), name, {
          color: Color.dark,
          textBaseline: 'top',
          fontSize: 12
        });

        if (description) {
          const y = textY + 10 + i * 20;
          const circle = new Circle(vec2.fromValues(marginLeft + textCircleRadius, y + 7), {
            radius: textCircleRadius,
            color: color
          });
          const descriptionText = new Text(vec2.fromValues(marginLeft + textCircleRadius * 2 + 5, y), `${name}: ${description}`, {
            color: Color.dark,
            textBaseline: 'top',
            fontSize: 12
          });

          graphics.add(circle, descriptionText);
        }

        text.opacity = opacity;
        graphics.add(text);

        const textWidth = text.getWidth();
        const circle = new Circle(vec2.fromValues(prevTextX + textCircleRadius, textY + 7), {
          radius: textCircleRadius,
          color: color
        });

        circle.opacity = opacity;

        graphics.add(circle);

        const textHitArea = new Rectangle(vec2.fromValues(prevTextX, textY), textWidth + 15, 15, { color: 'rgba(0,0,0,.1)' });
        textHitArea.tag = 'hitArea2';
        textHitArea.visible = false;
        graphics2.add(textHitArea);
        prevTextX += textWidth + 60;

        if (disabled) {
          continue;
        }

        if (type === ChartType.Line) {
          const vertices: Vertices = [];
          const length = values.length;

          for (let j = 0; j < length; j++) {
            const x = getX(j);
            const y = getY(values[j]);
            const isLast = j === length - 1;

            if (isLast) {
              graphics.add(new Circle(vec2.fromValues(x, y), { radius: 3, color }));
            } else {
              vertices.push(vec2.fromValues(x, y));
            }
          }

          if (vertices.length > 0) {
            graphics.add(Lines.fromVertices([vertices], { thickness: 1.5, color }));
          }
        } else if (type === ChartType.Bar) {
          const totalBarHalfWidth = totalBarCount * barOneWidth * 0.5;

          for (let j = 0, length = values.length; j < length; j++) {
            const x = getX(j) - oneHalfWidth + totalBarHalfWidth;

            if (typeof calculationGroup === 'string') {
              const max = get(groupMaxMap, calculationGroup);
              const y = (values[j] / max) * chartHeight + marginTop;
              const barHeight = y - marginTop;
              graphics2.add(
                new Rectangle(
                  vec2.fromValues(x + barCount * barOneWidth, chartHeight - barHeight + marginTop + 1),
                  barOneWidth,
                  barHeight,
                  { color }
                )
              );
            } else if (typeof standardValue === 'number') {
              const barHeight = chartHeight * (values[j] / standardValue);
              graphics2.add(
                new Rectangle(
                  vec2.fromValues(x + barCount * barOneWidth, chartHeight - barHeight + marginTop + 1),
                  barOneWidth,
                  barHeight,
                  { color }
                )
              );
            } else if (withCalculation) {
              const y = getBarY(values[j]);
              const barHeight = y - marginTop;
              graphics2.add(
                new Rectangle(
                  vec2.fromValues(x + barCount * barOneWidth, chartHeight - barHeight + marginTop + 1),
                  barOneWidth,
                  barHeight,
                  { color }
                )
              );
            } else {
              const barHeight = chartHeight * (values[j] * 0.01);
              graphics2.add(
                new Rectangle(
                  vec2.fromValues(x + barCount * barOneWidth, chartHeight - barHeight + marginTop + 1),
                  barOneWidth,
                  barHeight,
                  { color }
                )
              );
            }
          }
          barCount++;
        }
      }

      const dateLength = dates.length;
      const dateWidth = chartWidth / dateLength;
      const dateDisplayPoints = dateWidth * (9 / dateWidth);

      for (let i = 0, j = 0, length = dateLength; i < length; i++, j += dateWidth) {
        const x = i * oneWidth + marginLeft;

        if (i === 0 || j > dateDisplayPoints) {
          j = 0;
          const textX = i * oneWidth + marginLeft + oneWidth * 0.5;
          const text = new Text(vec2.fromValues(textX, chartHeight + marginTop + 5), dates[i], {
            fontSize: 9,
            textBaseline: 'top',
            textAlign: 'right',
            color: Color.dark
          });
          text.rotation = textRotation;
          graphics.add(text);
        }

        const rectangle = new Rectangle(vec2.fromValues(x, marginTop), oneWidth, chartHeight, {
          color: 'rgba(0,0,0,.1)'
        });
        rectangle.tag = 'hitArea';

        rectangle.visible = false;
        graphics2.add(rectangle);
      }

      const popupBackground = new RoundedRectangle(vec2.fromValues(0, 0), 100, 114, { radius: 4, color: '#fff' });
      popupBackground.tag = 'popupBackground';
      popupBackground.shadow = new DropShadow(0, 7, 14, 'rgba(0,0,0,0.1)');
      popupBackground.visible = false;

      const dateText = new Text(vec2.create(), 'dateText', { textBaseline: 'top', fontSize: 12, color: Color.hidden });
      dateText.tag = 'popupDateText';
      dateText.visible = false;
      graphics2.add(popupBackground, dateText);

      for (let i = 0, length = fields.length; i < length; i++) {
        const fieldText = new Text(vec2.create(), fields[i].name, { textBaseline: 'top', fontSize: 12, color: Color.dark });
        const fieldCircle = new Circle(vec2.create(), { radius: 5, color: fields[i].color });
        fieldText.tag = 'popupFieldText';
        fieldCircle.tag = 'popupFieldCircle';
        fieldText.visible = false;
        fieldCircle.visible = false;
        graphics2.add(fieldText, fieldCircle);
      }

      graphics.render();
      graphics2.render();

      return () => {
        canvas2.removeEventListener('mousemove', onMouseMove);
        canvas2.removeEventListener('mousedown', onMouseDown);
      };
    }
  }, [chartData, width]);

  return (
    <div className={styles.chartWrapper}>
      <canvas ref={canvasRef} className={styles.chart} />
      <canvas
        ref={canvas2Ref}
        className={classNames(styles.chart, styles.second)}
        style={{ cursor: isCursorPointer ? 'pointer' : 'default' }}
      />
    </div>
  );
});

export default Chart;
