import React from 'react';
import styles from './DateDisplay.module.scss';
import { DateTime } from 'luxon';
import { range } from 'lodash';
import classNames from 'classnames';

interface Props {
  date: DateTime;
  onClick: (date: DateTime) => void;
  startDate: DateTime | null;
  endDate: DateTime | null;
}

const DateDisplay: React.FC<Props> = ({ date, onClick, startDate, endDate }) => {
  const daysInMonth = range(42).map(i =>
    date
      .startOf('month')
      .startOf('week')
      .plus({ days: i })
  );

  const onClickDate = (dayInMonth: DateTime) => {
    if (!date.hasSame(dayInMonth, 'month')) return;
    onClick(dayInMonth);
  };

  return (
    <>
      <div className={styles.month}>{date.toFormat('yyyy년 M월')}</div>
      <div className={styles.daysWrapper}>
        {daysInMonth.map(dayInMonth => (
          <span
            className={classNames(styles.day, {
              [styles.today]: DateTime.local().hasSame(dayInMonth, 'days'),
              [styles.hidden]: !date.hasSame(dayInMonth, 'month'),
              [styles.startDate]: startDate && dayInMonth.hasSame(startDate, 'days'),
              [styles.endDate]: endDate && dayInMonth.hasSame(endDate, 'days'),
              [styles.between]: startDate && endDate && dayInMonth > startDate && dayInMonth < endDate,
              [styles.sameDate]: startDate && endDate && startDate.hasSame(dayInMonth, 'days') && endDate.hasSame(dayInMonth, 'days')
            })}
            onClick={() => onClickDate(dayInMonth)}
          >
            {dayInMonth.day}
          </span>
        ))}
      </div>
    </>
  );
};

export default DateDisplay;
