import React, { useEffect, useRef, useState } from 'react';
import styles from './Calendar.module.scss';
import Days from './Days/Days';
import DateDisplay from './DateDisplay/DateDisplay';
import { DateTime } from 'luxon';
import { range } from 'lodash';
import Ink from 'react-ink';

interface Props {
  initialDate: Date;
  onClickDate: (date: DateTime) => void;
  onClickConfirm: () => void;
  startDate: DateTime | null;
  endDate: DateTime | null;
  initDate: () => void;
}
const Calendar: React.FC<Props> = ({ initialDate, onClickDate, startDate, endDate, onClickConfirm, initDate }) => {
  const displayWrapperRef = useRef<HTMLDivElement>(null);
  const initialDateTime = DateTime.fromJSDate(initialDate);
  const diffMonths = Math.abs(initialDateTime.diffNow(['months', 'days']).months) + 1;

  useEffect(() => {
    if (displayWrapperRef.current) {
      displayWrapperRef.current.scrollTop = displayWrapperRef.current.scrollHeight;
    }
  }, []);

  return (
    <div className={styles.calendar}>
      <Days />
      <div className={styles.dateDisplayWrapper} ref={displayWrapperRef}>
        {range(diffMonths).map(diffMonth => (
          <DateDisplay
            date={initialDateTime.plus({ month: diffMonth })}
            onClick={onClickDate}
            startDate={startDate}
            endDate={endDate}
          />
        ))}
      </div>
      <div className={styles.footer}>
        <button className={styles.init} onClick={initDate}>
          초기화
          <Ink />
        </button>
        <button className={styles.confirm} onClick={onClickConfirm}>
          확인
          <Ink />
        </button>
      </div>
    </div>
  );
};

export default Calendar;
