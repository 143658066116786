import React, { useEffect } from 'react';
import styles from './DealerAnalyticsPage.module.scss';
import Layout from '../../components/Layout';
import DatePicker from '../../component-system/DatePicker/DatePicker';
import { useRouter } from '../../cores/useRouter';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { DealerActions } from '../../store/dealer';
import DealerAnalyticsTable from '../../components/DealerAnalyticsTable/DealerAnalyticsTable';
import { map } from 'lodash';
import Ink from 'react-ink';
import Content from '../../components/Content/Content';

const quickDates = [
  { start_date: DateTime.local().minus({ weeks: 1 }), end_date: DateTime.local(), label: '지난 1주일' },
  { start_date: DateTime.local().startOf('months'), end_date: DateTime.local(), label: '이번 달' },
  {
    start_date: DateTime.local()
      .minus({ months: 1 })
      .startOf('months'),
    end_date: DateTime.local()
      .minus({ months: 1 })
      .endOf('months'),
    label: '지난 달'
  },
  { start_date: DateTime.local().minus({ months: 1 }), end_date: DateTime.local(), label: '지난 1개월' },
  { start_date: DateTime.local().minus({ months: 3 }), end_date: DateTime.local(), label: '지난 3개월' },
  { start_date: DateTime.local().minus({ months: 6 }), end_date: DateTime.local(), label: '지난 6개월' }
];
const DealerAnalyticsPage: React.FC<{}> = () => {
  const { query, history, location } = useRouter();
  const { key } = location;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(DealerActions.getDealerActivity(query));
  }, [key]);

  const onClickDatePicker = (startDate: DateTime | null, endDate: DateTime | null) => {
    if (startDate && endDate) {
      query.set('start_date', startDate.toFormat('yyyy-MM-dd'));
      query.set('end_date', endDate.toFormat('yyyy-MM-dd'));
    } else if (startDate && !endDate) {
      query.set('start_date', startDate.toFormat('yyyy-MM-dd'));
      query.delete('end_date');
    } else {
      query.delete('start_date');
      query.delete('end_date');
    }
    history.push(`${location.pathname}?${query.toString()}`);
  };

  const renderQuickDateButtons = () => {
    return (
      <div className={styles.buttons}>
        {map(quickDates, date => (
          <div
            className={styles.quickDateButton}
            onClick={() => onClickDatePicker(date.start_date, date.end_date)}
            key={date.label}
          >
            {date.label}
            <Ink />
          </div>
        ))}
      </div>
    );
  };

  return (
    <Layout>
      <Content>
        <div className={styles.header}>
          <div className={styles.title}>담당자 별 데이터</div>
          <div className={styles.datePickerWrapper}>
            <DatePicker
              onClick={onClickDatePicker}
              initialStartDate={query.get('start_date')}
              initialEndDate={query.get('end_date')}
            />
            {renderQuickDateButtons()}
          </div>
        </div>
        <DealerAnalyticsTable />
      </Content>
    </Layout>
  );
};

export default DealerAnalyticsPage;
