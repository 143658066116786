import React, { useState } from 'react';
import styles from './Tag.module.scss';
import Ink from 'react-ink';

interface Props {
  hashId: string;
}
const Tag: React.FC<Props> = ({ hashId }) => {
  const onClickTag = () => {
    window.open(`http://heydealer.co.kr/profile/${hashId}`, '_blank', 'width=540, height=720, scrollbars=1,resizable=1');
  };

  return (
    <a className={styles.profileTag} onClick={onClickTag}>
      프로필 보기
      <Ink />
    </a>
  );
};

export default Tag;
