import React, { memo, useEffect } from 'react';
import styles from './SideBar.module.scss';
import images from '../../images';
import { DateTime } from 'luxon';
import { Scrollbars } from 'react-custom-scrollbars';
import SideBarList from '../SideBarList/SideBarList';
import classNames from 'classnames';

const SideBar: React.FC<{ isMobile?: boolean; isVisible?: boolean }> = memo(({ isMobile, isVisible }) => {
  return (
    <div className={classNames(styles.sideBar, isMobile && isVisible && styles.isVisible)}>
      <Scrollbars>
        <div className={styles.date}>
          <img src={images.calendar} alt="현황" className={styles.icon} />
          <span className={styles.text}>{DateTime.local().toISODate()}</span>
        </div>
        <SideBarList />
      </Scrollbars>
      <p className={styles.copyright}>Powered by 헤이딜러</p>
    </div>
  );
});

export default SideBar;
