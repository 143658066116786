import { DependencyList, useCallback, useEffect } from 'react';

export function usePagination(callback: () => void, deps: DependencyList = [], distance = 10) {
  const onScroll = useCallback(() => {
    const scrollBottom = window.pageYOffset + window.innerHeight;
    if (scrollBottom !== window.innerHeight && scrollBottom >= document.body.scrollHeight - distance) {
      callback();
    }
  }, deps);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });
}
