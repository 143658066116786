import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { Formik } from 'formik';
import { MdAutoFixNormal, MdOutlineAutoFixNormal } from 'react-icons/all';

import {
  checkHighlightDealerComment,
  deleteDealerComment,
  unCheckHighlightDealerComment,
  updateDealerComment
} from '../../../apis/dealerAPI';
import { Comment } from '../../../store/dealer/interface';
import Confirm from '../../Confirm/Confirm';

import styles from './CommentItem.module.scss';

interface Props {
  comment: Comment;
  refresh: () => void;
}

const CommentItem: React.FC<Props> = ({ comment, refresh }) => {
  const [isVisibleDeleteConfirm, setVisibleDeleteConfirm] = useState<boolean>(false);
  const { hash_id: commentId, content, author, created_at_display, is_highlight } = comment;
  const [isModify, setModify] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onSubmitModify = async ({ content }: { content: string }) => {
    await updateDealerComment(commentId, content);
    refresh();
  };

  const onDelete = async (isConfirmed: boolean) => {
    if (isConfirmed) {
      await deleteDealerComment(commentId);
      refresh();
    }
  };

  const onClickHighLight = async () => {
    if (is_highlight) {
      await unCheckHighlightDealerComment(commentId);
    } else {
      await checkHighlightDealerComment(commentId);
    }
    refresh();
  };

  const onToggleModify = useCallback(() => {
    setModify(!isModify);
  }, [isModify]);

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [isModify]);

  useEffect(() => {
    setModify(false);
  }, [comment]);

  return (
    <div className={classNames(styles.commentItem, isModify && styles.isModify, { [styles.highLight]: is_highlight })}>
      {isModify ? (
        <Formik
          initialValues={{
            content
          }}
          onSubmit={onSubmitModify}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit} className={styles.commentModifyForm}>
              <input
                ref={inputRef}
                type="text"
                name="content"
                value={values.content}
                onChange={handleChange}
                autoComplete="off"
              />
              <div className={styles.actions}>
                <button type="submit" className={styles.confirmButton}>
                  확인
                </button>
                <button type="button" onClick={onToggleModify} className={styles.cancelButton}>
                  취소
                </button>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <>
          <div className={styles.fields}>
            <div className={styles.date}>{created_at_display}</div>
            <div className={styles.content}>{content}</div>
            {author && <div className={styles.author}>by {author}</div>}
            <div className={styles.actions}>
              <button type="button" onClick={onClickHighLight} className={styles.highLightButton}>
                {is_highlight ? (
                  <MdAutoFixNormal className={styles.highLightIcon} size={20} />
                ) : (
                  <MdOutlineAutoFixNormal className={styles.highLightIcon} size={20} />
                )}
              </button>
              <button type="button" onClick={() => setVisibleDeleteConfirm(true)} className={styles.deleteButton}>
                삭제
              </button>
              <button type="button" onClick={onToggleModify} className={styles.modifyButton}>
                수정
              </button>
            </div>
          </div>
          <Confirm visible={isVisibleDeleteConfirm} onRest={onDelete} onClose={() => setVisibleDeleteConfirm(false)}>
            정말 삭제하시겠습니까?
          </Confirm>
        </>
      )}
    </div>
  );
};

export default CommentItem;
