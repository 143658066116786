import React, { FC, forwardRef, ForwardRefRenderFunction, HTMLAttributes, useCallback, useState } from 'react';
import styles from './InputField.module.scss';
import classNames from 'classnames';


interface Props {
  type?: string;
  name?: string;
  label?: string;
  value?: string;
  inputClassName?: string;
  onChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const InputField: ForwardRefRenderFunction<HTMLInputElement, Props & HTMLAttributes<HTMLInputElement>> = (
  { type = 'text', name, label, value, inputClassName, ...otherProps },
  ref
) => {
  const [isFocus, setFocus] = useState(false);

  const onFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    setFocus(false);
  }, []);

  return (
    <input
      className={classNames(styles.input, inputClassName)}
      ref={ref}
      type={type}
      name={name}
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      {...otherProps}
    />
  );
};

export default forwardRef(InputField);
