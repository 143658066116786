import React from 'react';
import styles from './XlsxDownLoadButton.module.scss';
import { MdFileDownload } from 'react-icons/md';
import Ink from 'react-ink';
import ClipLoader from 'react-spinners/ClipLoader';
import classNames from 'classnames';
interface Props {
  onClick: () => void;
  isLoading?: boolean;
}
const XlsxDownLoadButton: React.FC<Props> = ({ onClick, isLoading = false }) => {
  return (
    <button onClick={onClick} className={classNames(styles.button, { [styles.isLoading]: isLoading })}>
      {isLoading ? (
        <ClipLoader size={16} color="#1d6f42" />
      ) : (
        <>
          <MdFileDownload />
          엑셀로 내려받기
        </>
      )}
      <Ink />
    </button>
  );
};

export default XlsxDownLoadButton;
