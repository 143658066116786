import React from 'react';
import styles from './FieldSet.module.scss';

interface Props {
  label: string;
}
const FieldSet: React.FC<Props> = ({ label, children }) => {
  return (
    <div className={styles.fieldSet}>
      <label className={styles.label}>{label}</label>
      <>{children}</>
    </div>
  );
};

export default FieldSet;
