import React, { useEffect, useRef, useState } from 'react';
import styles from './InfoIcon.module.scss';
import ToolTip from '../ToolTip/ToolTip';
import isOutOfViewport from '../../cores/isOutOfViewport';
import { useHover } from '../../cores/useHover';
import classNames from 'classnames';

interface Props {
  content: string | React.ReactNode;
  className?: string;
}
const InfoIcon: React.FC<Props> = ({ content, className }) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isRightOut, setRightOut] = useState(false);
  const [isHover, onMouseOver, onMouseLeave] = useHover();

  useEffect(() => {
    if (tooltipRef.current) {
      const { right } = isOutOfViewport(tooltipRef.current);
      if (right) {
        setRightOut(true);
      }
    }
  }, [isHover]);

  return (
    <div className={styles.infoWrapper} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <div className={styles.info}>
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" className={styles.icon}>
          <g fill="none" fillRule="evenodd" transform="translate(-1 -1)">
            <circle cx="13.5" cy="13.5" r="6.5" fill="#DADFE1" />
            <g fill="#FFF">
              <path d="M13.51 9.151c1.654 0 1.654 2.566 0 2.566-1.655 0-1.655-2.566 0-2.566zM14.664 12.872h-2.31v5.003h2.31z" />
            </g>
          </g>
        </svg>
      </div>
      <ToolTip
        direction={isRightOut ? 'right' : 'left'}
        className={classNames(styles.tooltip, className)}
        isDisableAnimate={true}
        ref={tooltipRef}
        style={isRightOut ? { right: 35 } : { left: 35 }}
      >
        <div className={styles.template}>{content}</div>
      </ToolTip>
    </div>
  );
};

export default InfoIcon;
