import { ToastActions, ToastMessageTypes } from '../store/toast';
import { useDispatch } from 'react-redux';

export function useToast() {
  const dispatch = useDispatch();

  return {
    default: (message: string) => dispatch(ToastActions.createToast({ type: ToastMessageTypes.Default, message })),
    error: (message: string) => dispatch(ToastActions.createToast({ type: ToastMessageTypes.Error, message })),
    success: (message: string) => dispatch(ToastActions.createToast({ type: ToastMessageTypes.Success, message })),
    warning: (message: string) => dispatch(ToastActions.createToast({ type: ToastMessageTypes.Warning, message }))
  };
}
