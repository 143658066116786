import React, { FC } from 'react';

import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import { createDealerComment, getDealerComments } from '../../apis/dealerAPI';

import CommentItem from './CommentItem/CommentItem';

import styles from './Comment.module.scss';
import { Dealer } from '../../store/dealer/interface';
import { DealerActions } from '../../store/dealer';

interface Props {
  dealerId: string;
  dealerIndex: number;
  comments: Dealer['user_comments'];
}

const Comment: FC<Props> = ({ dealerId, dealerIndex, comments }) => {
  const dispatch = useDispatch();
  const refresh = async () => {
    const comments = await getDealerComments(dealerId);
    dispatch(DealerActions.setComments(dealerIndex, comments));
  };

  const onSubmit = async ({ content }: { content: string }, { resetForm }: any) => {
    if (dealerId) {
      await createDealerComment(dealerId, content);
      await refresh();
      await resetForm();
    }
  };

  return (
    <div className={styles.comment}>
      <div className={styles.header}>내부 코멘트</div>
      {comments?.map(comment => (
        <CommentItem key={comment.hash_id} comment={comment} refresh={refresh} />
      ))}
      <Formik initialValues={{ content: '' }} onSubmit={onSubmit}>
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} className={styles.commentForm}>
            <input type="text" name="content" value={values.content} onChange={handleChange} autoComplete="off" />
            <button type="submit">입력</button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Comment;
