import React, { useEffect, useRef, useState } from 'react';
import styles from './DateInput.module.scss';
import { DateObject, DateTime } from 'luxon';
import { MdDateRange, MdLockOutline } from 'react-icons/md';
import classNames from 'classnames';
interface Props {
  date: DateTime | null;
  label?: string;
}
const DateInput: React.FC<Props> = ({ date, label = '' }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isWriteMode, setWriteMode] = useState(false);
  const renderDate = () => {
    return (
      <div className={styles.dateWrapper}>
        <div className={styles.date}>
          {date ? (
            <span className={styles.text}>{date.toFormat('yyyy/M/d')}</span>
          ) : (
            <span className={styles.label}>{label}</span>
          )}
        </div>
        <MdDateRange size={18} />
        {date && <div className={classNames(styles.label, styles.outsideLabel)}>{label}</div>}
      </div>
    );
  };

  useEffect(() => {
    if (isWriteMode) {
      if (ref.current) {
        ref.current.focus();
      }
    }
  }, [isWriteMode]);

  return (
    <div
      onDoubleClick={() => {
        setWriteMode(true);
      }}
    >
      {/*{isWriteMode && (*/}
      {/*  <input*/}
      {/*    ref={ref}*/}
      {/*    type="text"*/}
      {/*    value={writeStartDate}*/}
      {/*    className={styles.writeInput}*/}
      {/*    onChange={(e: React.FormEvent<HTMLInputElement>) => {*/}
      {/*      let value = e.currentTarget.value;*/}

      {/*      if (/^\d{5}$/.test(value)) {*/}
      {/*        value = value.replace(/^(\d{4})(\d{1})/, '$1-$2');*/}
      {/*      } else if (/^\d{4}-\d{3}$/.test(value)) {*/}
      {/*        value = value.replace(/^(\d{4})-(\d{2})(\d{1})/, '$1-$2-$3');*/}
      {/*      }*/}

      {/*      setWriteStartDate(value);*/}
      {/*    }}*/}
      {/*    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {*/}
      {/*      if (e.which === 13 && ref.current) {*/}
      {/*        setVisibleDatePicker(false);*/}
      {/*        setWriteMode(false);*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {renderDate()}
    </div>
  );
};

export default DateInput;
