import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from './DropdownField.module.scss';
import classNames from 'classnames';
import Typography from '../Typography/Typography';
import { chain, size, get, map, findIndex, filter } from 'lodash';
import { Portal } from 'react-portal';
import { MdExpandMore, MdCheck, MdSearch } from 'react-icons/md';
import ClickOut from '../../components/ClickOut/ClickOut';
import { Scrollbars } from 'react-custom-scrollbars';
import Hangul from 'hangul-js';

interface SelectFieldOption {
  name: string | number;
  value: boolean | string | number | null;
}

interface Props {
  className?: string;
  label?: string;
  options: Array<SelectFieldOption>;
  value: string | number | null;
  onChange: (value: boolean | string | number | null) => void;
  nullLabel?: string;
}

const optionHeight = 36;

const DropdownField: FC<Props> = memo(({ label, className, options, value, onChange, nullLabel = '없음' }) => {
  const searchRef = useRef<HTMLInputElement | null>(null);
  const optionsRef = useRef<Scrollbars | null>(null);
  const dropdownRef = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setOpen] = useState(false);
  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 });
  const [search, setSearch] = useState('');
  const [width, setWidth] = useState(70);

  const selectedOptionIndex = findIndex(options, option => option.value === value);

  const onClickDropdown = useCallback(() => {
    setOpen(true);
  }, []);

  const onFocusDropdown = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    const optionsWidth = [];

    for (let i = 0; i < options.length; i++) {
      const span = document.createElement('span');
      span.innerText = options[i].name.toString();
      span.style.fontSize = '12px';
      document.body.appendChild(span);
      const width = span.offsetWidth;
      optionsWidth.push(width);
      document.body.removeChild(span);
    }

    setWidth(Math.max(Math.max(...optionsWidth) + 39, 70));
  }, [options]);

  useEffect(() => {
    if (!dropdownRef.current) {
      return;
    }

    const { left, top } = dropdownRef.current.getBoundingClientRect();
    setPosition({ x: left, y: top + dropdownRef.current.offsetHeight + 5 });
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setSearch('');
    }

    if (!dropdownRef.current) {
      return;
    }

    const { left, top } = dropdownRef.current.getBoundingClientRect();
    setPosition({ x: left, y: top + dropdownRef.current.offsetHeight + 5 });

    if (!searchRef.current) {
      return;
    }

    searchRef.current.focus();
  }, [isOpen]);

  useEffect(() => {
    if (!dropdownRef.current || selectedOptionIndex === -1) {
      return;
    }

    if (optionsRef.current) {
      const clientHalfHeight = optionsRef.current.getClientHeight() * 0.5;
      optionsRef.current.scrollTop(selectedOptionIndex * optionHeight - clientHalfHeight + optionHeight * 0.5);
    }
  }, [isOpen, selectedOptionIndex]);

  useEffect(() => {
    if (optionsRef.current) {
      optionsRef.current.scrollTop(0);
    }
  }, [search]);

  const hasInitialConsonant = /[ㄱ-ㅎㅏ-ㅣ]+/.test(search);
  const serializeOptions = [...options];
  const totalHeight = optionHeight * (size(serializeOptions) || 0);

  return (
    <div className={styles.dropdownField}>
      {label && (
        <Typography className={styles.label} variant="label">
          {label}
        </Typography>
      )}
      <button
        ref={dropdownRef}
        type="button"
        style={{ minWidth: width }}
        className={classNames(styles.dropdown, isOpen && styles.isOpen, className)}
        onClick={onClickDropdown}
        onFocus={onFocusDropdown}
      >
        <div className={styles.inner}>
          {selectedOptionIndex !== -1 && <span className={styles.name}>{get(options, [selectedOptionIndex, 'name'])}</span>}
          <span className={styles.icon}>
            <MdExpandMore />
          </span>
        </div>
      </button>
      {isOpen && (
        <Portal>
          <ClickOut
            className={styles.clickOutWrapper}
            style={{ left: x, top: y }}
            onClickOut={() => {
              setOpen(false);
            }}
          >
            <div className={styles.popup}>
              <div className={styles.search}>
                <span className={styles.icon}>
                  <MdSearch />
                </span>
                <input
                  ref={searchRef}
                  type="text"
                  placeholder="검색..."
                  value={search}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setSearch(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={styles.options} style={{ height: Math.min(totalHeight, 270) }}>
                <Scrollbars ref={ref => (optionsRef.current = ref)}>
                  <div className={styles.scroll}>
                    {map(
                      search !== ''
                        ? filter(serializeOptions, ({ name }) => {
                            const convertToStringName = name.toString();
                            const isMatched = convertToStringName.indexOf(search) !== -1;

                            if (isMatched) {
                              return true;
                            }

                            if (hasInitialConsonant) {
                              if (Hangul.search(convertToStringName, search) !== -1) {
                                return true;
                              }

                              return (
                                chain(Hangul.disassemble(convertToStringName, true))
                                  .map(i => get(i, 0))
                                  .join('')
                                  .value()
                                  .indexOf(search) !== -1
                              );
                            }

                            return false;
                          })
                        : serializeOptions,
                      ({ name, value: optionValue }) => {
                        const isSelected = optionValue === value;

                        return (
                          <div
                            key={name}
                            className={classNames(styles.option, isSelected && styles.isSelected)}
                            onClick={() => {
                              if (!isSelected) {
                                onChange(optionValue);
                              }
                              setOpen(false);
                            }}
                          >
                            {isSelected && (
                              <span className={styles.icon}>
                                <MdCheck />
                              </span>
                            )}
                            <div className={styles.inner}>{name}</div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </Scrollbars>
              </div>
            </div>
          </ClickOut>
        </Portal>
      )}
    </div>
  );
});

export default DropdownField;
