import React, { useEffect, useRef, useState } from 'react';
import styles from './DealerActivity.module.scss';
import { DealerActivityCountType, Dealer, Activities } from '../../../../store/dealer/interface';
import { map, maxBy } from 'lodash';

import { ReactComponent as ZeroMan } from '../../../../images/zero-man.svg';

interface Props {
  monthlyActivities: Dealer['monthly_activities'];
}

const BAR_WIDTH = 8;
const PADDING = 40;
const height = 200;

const getAverageCount = (list: DealerActivityCountType[]) => list.reduce((a, c) => a + c.count, 0) / list.length;

const BarCharts: React.FC<{ activities: Activities }> = ({ activities: { bid, selected, traded } }) => {
  const [width, setWidth] = useState(0);
  const dealerAcitvityRef = useRef<HTMLDivElement>(null);
  const onResize = () => {
    if (dealerAcitvityRef.current) {
      setWidth(dealerAcitvityRef.current.clientWidth / 3);
    }
  };

  const bidSum = getAverageCount(bid);
  const selectSum = getAverageCount(selected);
  const tradeSum = getAverageCount(traded);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  const renderBarGraph = (bidsCountList: DealerActivityCountType[]) => {
    const maxBid = maxBy(bidsCountList, bid => bid.count);
    return (
      <svg width={width} height={height}>
        {map(bidsCountList, (bid, index) => {
          const order = 5 - index;
          const barHeight = maxBid ? (height - 2 * PADDING) * (bid.count / maxBid.count) : 0;
          const barY = height - barHeight - PADDING;
          const getX = (index: number) => PADDING + (index * (width - PADDING * 2 - 6 * BAR_WIDTH)) / 5 + BAR_WIDTH * index;
          return (
            <>
              <rect width={BAR_WIDTH} height={barHeight} x={getX(order)} y={barY} fill="#00b0ff"></rect>
              <text
                text-anchor="middle"
                x={BAR_WIDTH / 2 + getX(order)}
                y={barY ? barY - 10 : height - PADDING - 10}
                fill="#00a7ff"
                font-size="12px"
              >
                {bid.count ? bid.count : 0}
              </text>
              <text
                text-anchor="middle"
                x={BAR_WIDTH / 2 + getX(order)}
                y={height - PADDING + 20}
                fill="#98a3ad"
                font-size="12px"
              >
                {bid.month}월
              </text>
            </>
          );
        })}
      </svg>
    );
  };

  return (
    <div className={styles.dealerActivity} ref={dealerAcitvityRef}>
      <div className={styles.barChartWrapper}>
        <div className={styles.title}>견적</div>
        <div className={styles.subtitle}>평균 견적수 {bidSum.toFixed(1)}건</div>
        {renderBarGraph(bid)}
      </div>
      <div className={styles.barChartWrapper}>
        <div className={styles.title}>선택</div>
        <div className={styles.subtitle}>평균 선택율 {bidSum ? ((selectSum / bidSum) * 100).toFixed(1) : '-'}%</div>
        {renderBarGraph(selected)}
      </div>
      <div className={styles.barChartWrapper}>
        <div className={styles.title}>거래</div>
        <div className={styles.subtitle}>누적 성사율 {bidSum ? ((tradeSum / bidSum) * 100).toFixed(1) : '-'}%</div>
        {renderBarGraph(traded)}
      </div>
    </div>
  );
};

const DealerActivity: React.FC<Props> = ({ monthlyActivities: { basic_auction, zero_auction } }) => (
  <div className={styles.dealerActivities}>
    <h3>
      제로 경매 <ZeroMan height={24} />
    </h3>
    <BarCharts activities={basic_auction} />
    <h3>일반 경매</h3>
    <BarCharts activities={zero_auction} />
  </div>
);

export default DealerActivity;
