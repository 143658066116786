import React, { memo } from 'react';
import classNames from 'classnames';
import styles from './SideBarItem.module.scss';
import { MenuItem } from '../../cores/sideBars';
import Ink from 'react-ink';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import base64url from 'base64-url';
import { reduce } from 'lodash';
import { useRouter } from '../../cores/useRouter';

interface SideBarItemProps extends RouteComponentProps {
  menuItem: MenuItem;
}

const SideBarItem: React.FC<SideBarItemProps> = memo(({ menuItem }) => {
  const { query } = useRouter();
  const key = query.get('key');

  const params = reduce(
    menuItem.params,
    (acc: any, value, key) => {
      acc[key] = value;
      return acc;
    },
    { key: base64url.encode(menuItem.label) }
  );

  return (
    <div className={classNames(styles.sideBarItem, key === params.key ? styles.active : '')}>
      <Link
        to={{
          pathname: `${menuItem.endPoint}`,
          search: new URLSearchParams(params).toString()
        }}
        className={styles.link}
      >
        <span className={styles.label}>{menuItem.label}</span>
        <Ink />
      </Link>
    </div>
  );
});

export default withRouter(SideBarItem);
