import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './DatePicker.module.scss';
import { DateTime } from 'luxon';
import DateInput from './DateInput/DateInput';
import Calendar from './Calendar/Calendar';

interface Props {
  initialDate?: Date;
  onClick: (startDate: DateTime | null, endDate: DateTime | null) => void;
  initialStartDate?: string | null;
  initialEndDate?: string | null;
}

const DatePicker: React.FC<Props> = ({ initialDate = new Date('2019/01/01'), onClick, initialStartDate, initialEndDate }) => {
  const [visibleDatePicker, setVisibleDatePicker] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [startDate, setStartDate] = useState<DateTime | null>(null);
  const [endDate, setEndDate] = useState<DateTime | null>(null);

  useEffect(() => {
    if (initialStartDate) {
      setStartDate(DateTime.fromFormat(initialStartDate, 'yyyy-MM-dd'));
      setClickCount(prev => prev + 1);
    } else {
      setStartDate(null);
    }
    if (initialEndDate) {
      setEndDate(DateTime.fromFormat(initialEndDate, 'yyyy-MM-dd'));
      setClickCount(prev => prev + 1);
    } else {
      setEndDate(null);
    }
  }, [initialStartDate, initialEndDate]);

  const onClickDate = (date: DateTime) => {
    if (clickCount % 2 === 0) {
      setStartDate(date);
      setEndDate(null);
      setClickCount(prev => prev + 1);
    } else {
      if (startDate && startDate <= date) {
        setEndDate(date);
        setClickCount(prev => prev + 1);
      }
    }
  };

  const initDate = () => {
    setClickCount(0);
    setStartDate(null);
    setEndDate(null);
  };

  const onClickConfirm = (): void => {
    onClick(startDate, endDate);
    setVisibleDatePicker(false);
  };

  return (
    <div className={styles.datePicker}>
      <div className={styles.dateInputWrapper} onClick={() => setVisibleDatePicker(prev => !prev)}>
        <DateInput date={startDate} label="시작 날짜" />
        <div className={styles.bar} />
        <DateInput date={endDate} label="끝 날짜" />
      </div>
      {visibleDatePicker && (
        <div className={styles.calendarWrapper}>
          <OutsideClickHandler onOutsideClick={onClickConfirm}>
            <Calendar
              initialDate={initialDate}
              onClickDate={onClickDate}
              onClickConfirm={onClickConfirm}
              startDate={startDate}
              endDate={endDate}
              initDate={initDate}
            />
          </OutsideClickHandler>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
