import React, { memo } from 'react';
import { get } from 'lodash';
import { toLocaleString } from '../../cores/toLocaleString';
import styles from './DealerAnalyticsTableRow.module.scss';
import { DealerActivity } from '../../store/dealer/interface';

interface Props {
  dealer: DealerActivity;
  index: number;
}

const DealerAnalyticsTableRow: React.FC<Props> = memo(({ dealer, index }) => {
  const fullName = get(dealer, 'full_name');
  const office = get(dealer, 'office');
  const mistakesCount = get(dealer, 'mistakes_count');
  const warningsCount = get(dealer, 'warnings_count');
  const isOdd = index % 2 !== 0;

  return (
    <>
      <tr className={isOdd ? styles.odd : styles.even}>
        <td>{fullName}</td>
        <td className={styles.office}>{office}</td>
        <td>{toLocaleString(dealer.basic_bids_count)}</td>
        <td>{toLocaleString(dealer.basic_selected_cars_count)}</td>
        <td>{toLocaleString(dealer.basic_traded_cars_count)}</td>
        <td>{toLocaleString(dealer.zero_bids_count)}</td>
        <td>{toLocaleString(dealer.zero_selected_cars_count)}</td>
        <td>{toLocaleString(dealer.zero_traded_cars_count)}</td>
        <td>{toLocaleString(dealer.sum_bids_count)}</td>
        <td>{toLocaleString(dealer.sum_selected_cars_count)}</td>
        <td>{toLocaleString(dealer.sum_traded_cars_count)}</td>
        <td>{toLocaleString(mistakesCount)}</td>
        <td>{toLocaleString(warningsCount)}</td>
      </tr>
    </>
  );
});

export default DealerAnalyticsTableRow;
