import React, { forwardRef, ForwardRefRenderFunction, HTMLAttributes } from 'react';
import styles from './InputField.module.scss';
import classNames from 'classnames';
import Typography from '../Typography/Typography';

interface Props {
  type?: string;
  name?: string;
  label?: string;
  value?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

const InputField: ForwardRefRenderFunction<HTMLInputElement, Props & HTMLAttributes<HTMLInputElement>> = (
  { type = 'text', name, label, value, className, inputClassName, labelClassName, ...otherProps },
  ref
) => {
  return (
    <label className={classNames(styles.inputField, className)}>
      {label && (
        <Typography className={classNames(styles.label, labelClassName)} variant="label">
          {label}
        </Typography>
      )}
      <input
        data-testid="input"
        ref={ref}
        className={classNames(styles.input, inputClassName)}
        type={type}
        name={name}
        value={value}
        {...otherProps}
      />
    </label>
  );
};

export default forwardRef(InputField);
