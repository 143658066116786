import React from 'react';
import styles from './SideBarList.module.scss';
import sideBars from '../../cores/sideBars';
import SideBarItem from '../SideBarItem/SideBarItem';
import { map } from 'lodash';
import images from '../../images';

const SideBarList: React.FC<{}> = () => {
  return (
    <div className={styles.sideBarList}>
      {map(sideBars, sideBar => (
        <div key={sideBar.category} className={styles.categoryWrapper}>
          <div className={styles.category}>
            <img src={images[sideBar.icon]} alt={sideBar.alt} className={styles.icon} />
            <span className={styles.text}>{sideBar.category}</span>
          </div>
          {map(sideBar.menuItemList, menuItem => (
            <SideBarItem key={menuItem.label} menuItem={menuItem} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default SideBarList;
