import React, { FC, useCallback } from 'react';
import styles from './Confirm.module.scss';
import { Portal } from 'react-portal';
import Ink from 'react-ink';

interface Props {
  visible: boolean;
  children: React.ReactNode;
  onRest: (isConfirmed: boolean) => void;
  onClose: () => void;
}

const Confirm: FC<Props> = ({ visible, children, onRest, onClose }) => {

  const onClickAction = useCallback((isConfirmed: boolean) => {
    return () => {
      onRest(isConfirmed);
      onClose();
    }
  }, [onRest, onClose]);

  if (!visible) {
    return null;
  }

  return (
    <Portal>
      <div className={styles.dimmer} />
      <div className={styles.confirm}>
        <div className={styles.popup}>
          <div className={styles.children}>
            {children}
          </div>
          <div className={styles.actions}>
            <button className={styles.cancel} onClick={onClickAction(false)}>
              취소
              <Ink />
            </button>
            <button className={styles.apply} onClick={onClickAction(true)}>
              확인
              <Ink />
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Confirm;
