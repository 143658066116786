import React, { FC } from 'react';

import classNames from 'classnames';

import Comment from '../../../../component-system/Comment/Comment';
import getResizeUrl from '../../../../cores/getResizeUrl';
import { toLocalePhoneNumber } from '../../../../cores/toLocalePhoneNumber';
import { Dealer } from '../../../../store/dealer/interface';
import InfoIcon from '../../../InfoIcon/InfoIcon';
import Tag from '../../../Tag/Tag';

import DealerActivity from './DealerActivity';

import styles from './DealersListItem.module.scss';

interface Props {
  dealer: Dealer;
  index: number;
}

const DealersListItem: FC<Props> = ({ dealer, index }) => {
  const hashId = dealer.hash_id;
  const username = dealer.username;
  const fullName = dealer.full_name;
  const phoneNumber = dealer.phone_number;
  const profileImage = dealer.profile_image_url;
  const officeDisplay = dealer.office_display;
  const successRate = dealer.success_rate;
  const tradedCarsCount = dealer.traded_cars_count;
  const recentTradedCarsCount = dealer.recent_traded_cars_count;
  const mistakesCount = dealer.mistakes_count;
  const warningsCount = dealer.recent_warnings_count;
  const averageRating = dealer.average_rating;
  const reviewRate = dealer.review_rate;
  const monthlyActivities = dealer.monthly_activities;

  return (
    <div className={styles.dealersListItem}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div
            className={styles.profile}
            style={{ background: profileImage ? `url(${getResizeUrl(100, 100, profileImage)}) no-repeat center` : '' }}
          >
            <div className={styles.tagWrapper}>
              <Tag hashId={hashId} />
            </div>
          </div>
          <div className={styles.information}>
            <div className={styles.username}>{username}</div>
            <div className={styles.fullName}>{fullName}</div>
            <div className={styles.office}>
              <span>{officeDisplay}</span>
            </div>
            <div className={styles.phoneNumberWrapper}>
              <div className={styles.phoneNumber}>
                <span className={styles.text}>{phoneNumber ? toLocalePhoneNumber(phoneNumber) : '전화번호 없음'}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.successRate}>
            <span className={styles.label}>
              <div className={styles.successRateText}>
                성사율
                <InfoIcon content="최근 3개월 선택(낙찰)대비 거래차량 비율" />
              </div>
              <span className={styles.percent}>{successRate}%</span>
            </span>
            <span className={styles.progress}>
              <span className={styles.inner} style={{ width: `${successRate}%` }} />
            </span>
          </div>
          <div className={styles.starRate}>
            <span className={styles.inner}>
              <div className={styles.starWrap}>
                <div className={styles.starLabel}>평점</div>
                <div className={styles.starValue}>
                  <span className={styles.star} />
                  {averageRating}{' '}
                  <InfoIcon
                    content={
                      <div>
                        1) 최근 30대 거래 차량 기준으로 계산
                        <br />
                        2) 미입력 평점은 6.5점으로 적용하되
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;7일 내 거래차량은 후기 미입력 시<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;평점 count에서 제외 (후기 입력 시 포함)
                        <br />
                        3) 비공개 후기 평점도 count에 적용
                      </div>
                    }
                  />
                </div>
              </div>
              <div className={classNames(styles.starWrap, styles.isIncludeNoInput)}>
                <div className={styles.starLabel}>후기 입력률 {reviewRate}%</div>
              </div>
            </span>
          </div>
          <div className={styles.tradeCount}>
            <span className={styles.label}>거래대수</span>
            <span className={styles.value}>
              {tradedCarsCount}대 <span className={styles.hidden}>(최근 {recentTradedCarsCount}대)</span>
            </span>
          </div>
          <div className={styles.warning}>
            <span className={styles.warningIcons}>
              {Array(3).map((_, i) => {
                return <span key={i} className={classNames(styles.warningIcon, i < warningsCount && styles.isActivated)} />;
              })}
            </span>
            <span className={styles.mistakeCount}>견적실수 누적 {mistakesCount}회</span>
          </div>
        </div>
      </div>
      <DealerActivity monthlyActivities={monthlyActivities} />
      <Comment dealerId={dealer.hash_id} comments={dealer.user_comments} dealerIndex={index} />
    </div>
  );
};

export default DealersListItem;
