import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './DealersHeader.module.scss';
import { useFormik } from 'formik';
import { camelCase, get, snakeCase } from 'lodash';
import InputField from '../../../component-system/InputField/InputField';
import Ink from 'react-ink';
import { useHistory, useLocation } from 'react-router-dom';
import { MdExpandMore } from 'react-icons/md';
import classNames from 'classnames';
import { DateTime } from 'luxon';

interface Props {}

interface DealersSearchValues {
  name: string | null;
  office: string | null;
}

const fields = ['name', 'office'];

const Column: FC<{ label: string }> = ({ label, children }) => {
  return (
    <div className={styles.column}>
      <div className={styles.label}>{label}</div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

const DealersHeader: FC<Props> = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [toggle, setToggle] = useState(true);

  const toggleSearchForm = useCallback(() => {
    setToggle(prevToggle => !prevToggle);
  }, []);

  const onSubmit = useCallback(
    (values: DealersSearchValues) => {
      const query = new URLSearchParams();

      for (const i in values) {
        const value = get(values, i);

        if (value === null || (Array.isArray(value) && value.length <= 0)) {
          continue;
        }

        const name = snakeCase(i);

        if (Array.isArray(value)) {
          for (const j in value) {
            query.append(name, value[j]);
          }
        } else if (name === 'min_created_at_date' || name === 'max_created_at_date') {
          query.set(name, DateTime.fromFormat(value, 'yyyy-MM-dd HH:mm:ss').toFormat('yyyy-MM-dd'));
        } else {
          query.set(name, value);
        }
      }

      const currentQuery = new URLSearchParams(search);
      const key = currentQuery.get('key');
      const order = currentQuery.get('order');

      if (key) {
        query.set('key', key);
      }

      if (order) {
        query.set('order', order);
      }

      history.push(`?${query.toString()}`);
    },
    [search]
  );

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik<DealersSearchValues>({
    initialValues: {
      name: null,
      office: null
    },
    onSubmit
  });

  useEffect(() => {
    const currentQuery = new URLSearchParams(search);

    for (let i = 0; i < fields.length; i++) {
      const key = fields[i];
      const values = currentQuery.getAll(key);
      const value = get(values, 0);
      const camelCasedName = camelCase(key);
      setFieldValue(camelCasedName, value || null);
    }
  }, [search, setFieldValue]);

  return (
    <div className={styles.dealersHeader}>
      <div className={styles.header}>
        <div className={styles.title}>담당자 리스트</div>
        <button className={classNames(styles.toggleSearchForm, toggle && styles.isActivated)} onClick={toggleSearchForm}>
          <span className={styles.text}>검색</span>
          <MdExpandMore />
          <Ink />
        </button>
      </div>
      {toggle && (
        <form onSubmit={handleSubmit}>
          <div className={styles.basicInformation}>
            <Column label="이름">
              <InputField name="name" value={values.name || ''} onChange={handleChange} />
            </Column>
            <Column label="소속 지점">
              <InputField name="office" value={values.office || ''} onChange={handleChange} />
            </Column>
            <div className={styles.actions}>
              <button className={styles.submit} type="submit">
                검색하기
                <Ink />
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default DealersHeader;
