import { Color } from './toChartData';
import { get, map, size } from 'lodash';
import { DateTime } from 'luxon';

interface TableDataField {
  name: string;
  value?: string;
  color?: Color;
  numeric?: boolean;
  tenDaysAgoDivisionLine?: boolean;
  getValue?: (fields: any) => number | string;
}

export interface TableDataOptions {
  tenDaysAgoDivisionLine?: boolean;
  fields: Array<TableDataField>;
}

type TableDataSerialize = Array<
  Array<{
    name?: string;
    value?: number | string;
    getValue?: () => number | string;
    color?: Color;
    tenDaysAgoDivisionLine: boolean;
  }>
>;

const tenDayAgo = DateTime.local().minus({ days: 10 });

export function toTableData(data: any, options: TableDataOptions): TableDataSerialize | null {
  if (!options) {
    return null;
  }

  const length = size(data);

  return map(data, (fields, key: number, datas) => {
    let tenDaysAgoDivisionLine = false;

    if (options.tenDaysAgoDivisionLine && key < length - 1) {
      const a = DateTime.fromFormat(get(fields, 'date'), 'yyyy-MM-dd')
        .diff(tenDayAgo)
        .milliseconds;

      const b = DateTime.fromFormat(get(datas, [key + 1, 'date']), 'yyyy-MM-dd')
        .diff(tenDayAgo)
        .normalize().milliseconds;

      if (a > 0 && 0 > b) {
        tenDaysAgoDivisionLine = true;
      }
    }


    return map(options.fields, ({ value, getValue, color, numeric }) => {
      return {
        name: value,
        color,
        numeric,
        value: value ? (get(fields, value) as number | string) : getValue ? getValue(fields) : '-',
        tenDaysAgoDivisionLine
      };
    });
  });
}
