import React from 'react';
import { Info } from 'luxon';
import styles from './Days.module.scss';

const Days: React.FC<{}> = () => {
  return (
    <div className={styles.days}>
      {Info.weekdays('short').map((weekDay, i) => {
        return <div key={i}>{weekDay}</div>;
      })}
    </div>
  );
};

export default Days;
