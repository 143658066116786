import { Action, handleActions } from 'redux-actions';
import { action, PayloadAction } from 'typesafe-actions';
import produce from 'immer';
import createAsyncSagaAction from '../../cores/createAsyncSagaAction';
import axios, { AxiosResponse, CancelToken } from 'axios';
import { createAsyncSagaReducerMap } from '../../cores/createAsyncSagaReducerMap';
import { Dealer, DealerActivity } from './interface';
import { getAuthorizationHeader } from '../../cores/getAuthorizationHeader';
import { uniqBy } from 'lodash';

interface DealerState {
  dealers: Dealer[] | null;
  dealersCount: number | null;
  isStopPagination: boolean;
  dealerActivity: DealerActivity[] | null;
}

export interface EnvelopDepthPayload<T = any> {
  meta: {
    count: number;
    next: string | null;
    previous: string | null;
  };
  results: Array<T>;
}

export enum DealerTypes {
  getDealers = '@dealers/getDealers',
  setTruncateDealers = '@dealers/setTruncateDealers',
  getDealerActivity = '@dealers/getDealerActivity',
  setComments = '@dealers/getComments'
}
const initialState: DealerState = {
  dealers: null,
  dealersCount: null,
  isStopPagination: false,
  dealerActivity: null
};

export const DealerActions = {
  getDealers: createAsyncSagaAction(DealerTypes.getDealers, (params: URLSearchParams, cancelToken?: CancelToken) => {
    params.set('envelope', 'true');
    return axios.get(`users/`, { params, headers: getAuthorizationHeader() });
  }),
  getDealerActivity: createAsyncSagaAction(
    DealerTypes.getDealerActivity,
    (params: URLSearchParams, cancelToken?: CancelToken) => {
      return axios.get(`user_activity/`, { params, headers: getAuthorizationHeader() });
    }
  ),
  setTruncateDealers: () => action(DealerTypes.setTruncateDealers),
  setComments: (index: number, comments: Dealer['user_comments']) => action(DealerTypes.setComments, { index, comments })
};

export default handleActions<DealerState, any>(
  {
    ...createAsyncSagaReducerMap(DealerTypes.getDealers, {
      onSuccess: (state, action: PayloadAction<string, AxiosResponse<EnvelopDepthPayload<Dealer>>>) => {
        const { meta, results } = action.payload.data;
        return produce(state, draft => {
          if (state.dealers) {
            draft.dealers = uniqBy([...state.dealers, ...results], 'hash_id');
          } else {
            draft.dealers = results;
          }

          draft.dealersCount = meta.count;
          draft.isStopPagination = meta.next === null;
        });
      }
    }),
    ...createAsyncSagaReducerMap(DealerTypes.getDealerActivity, {
      onSuccess: (state, action: PayloadAction<string, AxiosResponse<DealerActivity[]>>) => {
        return produce(state, draft => {
          const { data } = action.payload;
          draft.dealerActivity = data.map(datum => ({
            ...datum,
            sum_bids_count: datum.zero_bids_count + datum.basic_bids_count,
            sum_selected_cars_count: datum.zero_selected_cars_count + datum.basic_selected_cars_count,
            sum_traded_cars_count: datum.zero_traded_cars_count + datum.basic_traded_cars_count
          }));
        });
      }
    }),
    [DealerTypes.setTruncateDealers]: state => {
      return produce(state, draft => {
        draft.dealers = [];
      });
    },
    [DealerTypes.setComments]: (state, action: Action<{ index: number; comments: Dealer['user_comments'] }>) =>
      produce(state, draft => {
        if (!draft.dealers) return;
        draft.dealers[action.payload.index]['user_comments'] = action.payload.comments;
      })
  },
  initialState
);
