import pxios from './pxios';
import { Dealer } from '../store/dealer/interface';

export const createDealerComment = async (dealerId: string, content: string) => {
  return await pxios.post(`/users/${dealerId}/user_comments/`, { content });
};

export const getDealerComments = async (dealerId: string): Promise<Dealer['user_comments']> => {
  return (await pxios.get(`/users/${dealerId}/user_comments/`)).data;
};

export const updateDealerComment = async (commentId: number, content: string): Promise<void> => {
  return await pxios.patch(`/user_comments/${commentId}/`, { content });
};

export const checkHighlightDealerComment = async (commentId: number): Promise<void> => {
  return await pxios.post(`/user_comments/${commentId}/highlight/`);
};

export const unCheckHighlightDealerComment = async (commentId: number): Promise<void> => {
  return await pxios.delete(`/user_comments/${commentId}/highlight/`);
};

export const deleteDealerComment = async (commentId: number): Promise<void> => {
  return await pxios.delete(`/user_comments/${commentId}/`);
};
