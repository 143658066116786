import React from 'react';
import styles from './Header.module.scss';
import Ink from 'react-ink';
import { useDispatch } from 'react-redux';
import { AuthenticationActions } from '../../store/authentication';
import HeaderLogo from '../../images/logoHeader.png';

interface Props {
  isLogin: boolean;
}
const Header: React.FC<Props> = ({ isLogin }) => {
  const dispatch = useDispatch();
  const onClickLogout = () => {
    dispatch(AuthenticationActions.setLogout());
  };
  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <a href="/">
          <span className={styles.logo} />
        </a>
      </div>
      <div className={styles.right}>
        {!isLogin && (
          <div className={styles.button} onClick={onClickLogout}>
            로그아웃
            <Ink />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
