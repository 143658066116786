import React, { FC, useEffect } from 'react';
import styles from './LoginPage.module.scss';
import base64url from 'base64-url';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import { RouteComponentProps } from 'react-router-dom';
import Login from '../../components/Login/Login';
import { useTypedSelector } from '../../cores/useTypedSelector';
import Layout from '../../components/Layout';

interface Props {}

const LoginPage: FC<Props & RouteComponentProps> = ({ location: { search }, history }) => {
  const { token, isInitialized } = useTypedSelector(({ authentication }) => authentication);
  const query = new URLSearchParams(search);
  const previousDecoded = query.get('previous');
  const previous = previousDecoded ? base64url.decode(previousDecoded) : null;
  const isPermit = token && isInitialized;

  useEffect(() => {
    if (isPermit) {
      if (previous) {
        history.replace(previous);
      } else {
        history.replace('/');
      }
    }
  }, [previous, isPermit]);

  return (
    <Layout title="로그인" isLogin={true}>
      <div className={styles.content}>
        <Login />
        <div className={styles.warning}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
            <g fill="none" fillRule="evenodd" transform="translate(-1 -1)">
              <circle cx="13.5" cy="13.5" r="6.5" stroke="#fff" />
              <g fill="#FFF">
                <path d="M13.51 9.151c1.654 0 1.654 2.566 0 2.566-1.655 0-1.655-2.566 0-2.566zM14.664 12.872h-2.31v5.003h2.31z" />
              </g>
            </g>
          </svg>
          비정상적인 접속을 시도하지 마십시요. 접속 IP가 기록되고 있습니다.
        </div>
        <div className={styles.dimmer} />
      </div>
    </Layout>
  );
};

export default LoginPage;
