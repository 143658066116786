import produce from 'immer';
import { Reducer, handleActions, Action } from 'redux-actions';
import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';

export enum ResponsiveTypes {
  setVisibleSidebar = '@responsive/setVisibleSidebar'
}

export interface ResponsiveState {
  isMobile: boolean;
  isVisibleSidebar: boolean;
}

export const ResponsiveActions = {
  setVisibleSidebar: (isVisible: boolean) => action(ResponsiveTypes.setVisibleSidebar, isVisible)
};

const initialState: ResponsiveState = {
  isMobile: window.innerWidth <= 720,
  isVisibleSidebar: false
};

export default handleActions<ResponsiveState, any>(
  {
    [ResponsiveTypes.setVisibleSidebar]: (state, action: Action<boolean>) => {
      return produce(state, draft => {
        draft.isVisibleSidebar = action.payload;
      });
    }
  },
  initialState
);
