import { find } from 'lodash';
import { vec2 } from 'gl-matrix';

export const PI = Math.PI;
export const PI2 = PI * 2;

export enum GeometricTypes {
  NONE,
  TEXT,
  LINES,
  CIRCLE,
  RECTANGLE,
  ROUNDED_RECTANGLE
}

export class DropShadow {
  public shadowBlur: number;
  public shadowOffsetX: number;
  public shadowOffsetY: number;
  public shadowColor: string;

  constructor(shadowOffsetX: number, shadowOffsetY: number, shadowBlur: number, shadowColor: string) {
    this.shadowBlur = shadowBlur;
    this.shadowOffsetX = shadowOffsetX;
    this.shadowOffsetY = shadowOffsetY;
    this.shadowColor = shadowColor;
  }
}

export class Geometric {
  public type: GeometricTypes;
  public visible: boolean = true;
  public opacity: number = 1;
  public shadow?: DropShadow;

  private _tag!: string;
  private _parent!: Graphics;

  constructor(type: GeometricTypes = GeometricTypes.NONE) {
    this.type = type;
  }

  set tag(tag: string) {
    this._tag = tag;
  }

  get tag() {
    return this._tag;
  }

  set parent(parent: Graphics) {
    this._parent = parent;
  }

  get parent() {
    return this._parent;
  }
}

interface LinesOptions {
  thickness?: number;
  color?: string;
}

export type Vertices = Array<vec2>;
export type LineVertices = Array<Vertices>;

export class Lines extends Geometric {
  public vertices: LineVertices;
  public thickness: number = 1;
  public color: string;

  constructor(vertices: LineVertices, { thickness = 1, color = '#000000' }: LinesOptions = {}) {
    super(GeometricTypes.LINES);

    this.vertices = vertices;
    this.thickness = thickness;
    this.color = color;
  }

  static fromVertices(vertices: LineVertices, { thickness = 1, color = '#000000' }: LinesOptions = {}) {
    const lines = new Lines(vertices);
    lines.color = color;
    lines.thickness = thickness;
    return lines;
  }
}

interface CircleOptions {
  radius?: number;
  color?: string;
}

export class Circle extends Geometric {
  public position: vec2;
  public radius: number;
  public color: string;

  constructor(position: vec2, { radius = 1, color = '#000000' }: CircleOptions = {}) {
    super(GeometricTypes.CIRCLE);

    this.position = position;
    this.radius = radius;
    this.color = color;
  }
}

interface RectangleOptions {
  color?: string;
}

export class Rectangle extends Geometric {
  public position: vec2;
  public width: number;
  public height: number;
  public color: string;

  constructor(position: vec2, width: number, height: number, { color = '#000000' }: RectangleOptions = {}) {
    super(GeometricTypes.RECTANGLE);

    this.position = position;
    this.width = width;
    this.height = height;
    this.color = color;
  }
}

interface RoundedRectangleOptions {
  color?: string;
  radius?: number;
}

export class RoundedRectangle extends Geometric {
  public position: vec2;
  public width: number;
  public height: number;
  public radius: number;
  public color: string;

  constructor(position: vec2, width: number, height: number, { radius = 0, color = '#000000' }: RoundedRectangleOptions = {}) {
    super(GeometricTypes.ROUNDED_RECTANGLE);

    this.position = position;
    this.width = width;
    this.height = height;
    this.radius = radius;
    this.color = color;
  }
}

interface TextOptions {
  color?: string;
  rotation?: number;
  textAlign?: CanvasTextAlign;
  textBaseline?: CanvasTextBaseline;
  fontWeight?: string;
  fontSize?: number;
  fontFamily?: string;
}

export class Text extends Geometric {
  public position: vec2;
  public rotation: number;
  public color: string;
  public textAlign: CanvasTextAlign;
  public textBaseline: CanvasTextBaseline;
  public fontWeight: string;
  public fontSize: number;
  public fontFamily: string;
  private _text: string;

  constructor(
    position: vec2,
    text: string,
    {
      color = '#000000',
      rotation = 0,
      textAlign = 'left',
      textBaseline = 'middle',
      fontSize = 14,
      fontWeight = 'normal',
      fontFamily = 'Spoqa Han Sans'
    }: TextOptions = {}
  ) {
    super(GeometricTypes.TEXT);

    this.position = position;
    this.rotation = rotation;
    this.color = color;
    this.textAlign = textAlign;
    this.textBaseline = textBaseline;
    this.fontFamily = fontFamily;
    this.fontWeight = fontWeight;
    this.fontSize = fontSize;
    this._text = text;
  }

  get text() {
    return this._text;
  }

  set text(text: string) {
    this._text = text;
  }

  getWidth() {
    this.parent.context.save();
    this.parent.context.beginPath();
    this.parent.context.font = `${this.fontWeight} ${this.fontSize}px ${this.fontFamily}`;
    const { width } = this.parent.context.measureText(this.text);
    this.parent.context.restore();
    return width;
  }
}

export class Graphics {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  geometric: Array<Geometric> = [];

  constructor(canvas: HTMLCanvasElement, options?: { width: number; height: number }) {
    this.canvas = canvas;
    this.context = canvas.getContext('2d')!;

    if (options) {
      if (options.width) {
        canvas.width = options.width;
      }

      if (options.height) {
        canvas.height = options.height;
      }
    }
  }

  add(...geometric: Geometric[]) {
    for (let i = 0, length = geometric.length; i < length; i++) {
      geometric[i].parent = this;
    }

    this.geometric.push(...geometric);
  }

  findObjectTag<T extends Geometric>(targetTag: string): T | null {
    return (find(this.geometric, ({ tag }) => tag === targetTag) as T) || null;
  }

  findAllObjectTag<T extends Geometric>(targetTag: string): Array<T> {
    const findObjects: Array<T> = [];
    for (let i = 0, length = this.geometric.length; i < length; i++) {
      if (this.geometric[i].tag === targetTag) {
        findObjects.push(this.geometric[i] as T);
      }
    }

    return findObjects;
  }

  drawLines({ vertices, thickness, color }: Lines) {
    this.context.beginPath();
    this.context.strokeStyle = color;

    this.context.lineWidth = thickness;

    for (let i = 0, length = vertices.length; i < length; i++) {
      this.context.moveTo(Math.round(vertices[i][0][0]) + 0.5, Math.round(vertices[i][0][1]) + 0.5);

      for (let j = 1, length2 = vertices[i].length; j < length2; j++) {
        const vertex = vertices[i][j];
        this.context.lineTo(Math.round(vertex[0]) + 0.5, Math.round(vertex[1]) + 0.5);
      }

      this.context.stroke();
    }
  }

  drawText({ position, text, color, rotation, opacity, textAlign, textBaseline, fontWeight, fontSize, fontFamily }: Text) {
    const x = position[0];
    const y = position[1];
    this.context.save();
    this.context.beginPath();
    this.context.translate(x, y);
    this.context.rotate(rotation);
    this.context.translate(-x, -y);
    this.context.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
    this.context.textAlign = textAlign;
    this.context.textBaseline = textBaseline;
    this.context.globalAlpha = opacity;
    this.context.fillStyle = color;
    this.context.fillText(text, x, y);
    this.context.restore();
  }

  drawRectangle({ position, width, height, color }: Rectangle) {
    this.context.beginPath();
    this.context.fillStyle = color;
    this.context.fillRect(position[0], position[1], width, height);
  }

  drawRoundedRectangle({ position, width, height, color, radius }: RoundedRectangle) {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    const x = position[0];
    const y = position[1];
    this.context.beginPath();
    this.context.fillStyle = color;
    this.context.moveTo(x + radius, y);
    this.context.arcTo(x + width, y, x + width, y + height, radius);
    this.context.arcTo(x + width, y + height, x, y + height, radius);
    this.context.arcTo(x, y + height, x, y, radius);
    this.context.arcTo(x, y, x + width, y, radius);
    this.context.closePath();
    this.context.fill();
  }

  drawCircle({ position, radius, color, opacity }: Circle) {
    this.context.beginPath();
    this.context.save();
    this.context.globalAlpha = opacity;
    this.context.fillStyle = color;
    this.context.arc(position[0], position[1], radius, 0, PI2, false);
    this.context.fill();
    this.context.restore();
  }

  setDropShadow({ shadowBlur, shadowColor, shadowOffsetX, shadowOffsetY }: DropShadow) {
    this.context.shadowBlur = shadowBlur;
    this.context.shadowColor = shadowColor;
    this.context.shadowOffsetX = shadowOffsetX;
    this.context.shadowOffsetY = shadowOffsetY;
  }

  clearDropShadow() {
    this.context.shadowBlur = 0;
    this.context.shadowColor = '';
    this.context.shadowOffsetX = 0;
    this.context.shadowOffsetY = 0;
  }

  render() {
    const length = this.geometric.length;

    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    for (let i = 0; i < length; i++) {
      const geometric = this.geometric[i];

      if (!geometric.visible) {
        continue;
      }

      const hasShadow = geometric.shadow;

      if (hasShadow) {
        this.setDropShadow(geometric.shadow!);
      }

      switch (geometric.type) {
        case GeometricTypes.LINES:
          this.drawLines(geometric as Lines);
          break;
        case GeometricTypes.TEXT:
          this.drawText(geometric as Text);
          break;
        case GeometricTypes.RECTANGLE:
          this.drawRectangle(geometric as Rectangle);
          break;
        case GeometricTypes.CIRCLE:
          this.drawCircle(geometric as Circle);
          break;
        case GeometricTypes.ROUNDED_RECTANGLE:
          this.drawRoundedRectangle(geometric as RoundedRectangle);
          break;
      }

      if (hasShadow) {
        this.clearDropShadow();
      }
    }
  }

  dispose() {
    this.geometric.splice(0, this.geometric.length);
  }
}
